const menuBlock = document.getElementById("mobile-menu");
const menuBtn = document.getElementById("hamburger");
/* Small add for hamburger stiles */
const header = document.querySelector('.header');

menuBtn.addEventListener('click', () => {
  header.classList.toggle('open');
  menuBlock.classList.toggle("open");
});


